import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";

export default function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];
  const [activeLink, setActiveLink] = useState(
    currentPath !== "" ? currentPath : "home"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = (link) => {
    setIsMenuOpen(true);
    setActiveLink(link);
  };

  const links = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Privacy Policy", path: "/privacy-policy" },
    { name: "Term Condition", path: "/term-condition" },
    { name: "Contact Us", path: "/contact-us" },
  ];

  const linkClasses = (link) =>
    `block py-2 px-3 rounded md:p-0 ${
      activeLink === link
        ? "font-bold text-yellow-300 border-b-red-300 border-b-4"
        : ""
    }`;

  return (
    <header className="focus:ring-4 focus:outline-none p-2 my-2 rounded-lg bg-purple-500 text-white mx-3 z-10">
      <nav className="border-gray-200">
        <div className="flex flex-wrap items-center justify-between mx-auto p-4">
          <div
            className={`container items-center justify-between w-full md:flex md:w-auto ${
              isMenuOpen ? "hidden" : ""
            }`}
            id="navbar-search"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 md:flex-row md:mt-0 md:border-0 border-0">
              {links.map((link) => (
                <li key={link.path}>
                  <Link
                    to={link.path}
                    className={linkClasses(link.name.toLowerCase())}
                    onClick={() => handleLinkClick(link.name.toLowerCase())}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <Link to="/" className="flex items-center space-x-3 md:order-1">
            {/* <h1 className="font-extrabold text-center text-5xl text-yellow-200">
              Web 05
            </h1> */}
            <img src="/navbar.png" className="h-15" alt="edxvision.xyz" />
          </Link>
          <div className="flex">
            <button
              data-collapse-toggle="navbar-search"
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden hover:bg-orange-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-search"
              aria-expanded="false"
              onClick={handleMenuToggle}
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
        </div>
      </nav>
    </header>
  );
}
