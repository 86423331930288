import React from "react";
import { Link } from "react-router-dom";

export default function GameCard({ data }) {
  return (
    // <div className="relative flex cursor-pointer flex-col justify-end gap-3 overflow-hidden rounded-lg bg-pink-600 text-white border-8 border-green-200">
    //   <Link to={`/single?slug=${data.slug}`}>
    //     <img className="w-full rounded-md" src={data.logo} alt={data.title} />
    //     <p className="text-lg font-bold capitalize my-4 text-center">
    //       {data.title}
    //     </p>
    //   </Link>
    // </div>
    <div className="relative rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 hover:shadow-2xl group">
      <div>
        <img
          src={data.logo}
          alt={data.title}
          className="w-full h-64 object-cover"
        />
        <div
          className={`absolute inset-x-0 bottom-0 p-4 text-center bg-opacity-80 transition-transform transform group-hover:translate-y-full group-hover:opacity-0 z-10`}
        >
          <h2 className="text-xl font-bold text-white">{data.title}</h2>
        </div>
      </div>
      <div className="absolute inset-0 flex flex-col justify-center items-center p-4 bg-green-500 bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <h2 className="text-xl font-bold text-white my-2">{data.title}</h2>
        <Link
          to={`/single?slug=${data.slug}`}
          className="text-yellow-200 px-6 py-3 bg-purple-800 rounded-full transition duration-300 shadow-md hover:shadow-lg"
        >
          Play Now
        </Link>
      </div>
    </div>
  );
}
