import { Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Component/Navbar";
import Home from "./Component/Home";
import Footer from "./Component/Footer";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import About from "./Component/About";
import Single from "./Component/Single";
import ContactUs from "./Component/ContactUs";
import TermCondition from "./Component/TermCondition";

function App() {
  return (
    <div className="flex flex-col min-h-screen cursor-pointer bg-gradient-to-b from-red-400 via-purple-300 to-sky-400 relative overflow-hidden">
      <Navbar />
      <div className="flex-grow container mx-auto py-3">
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/about" element={<About />} />
          <Route path="/single" element={<Single />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/term-condition" element={<TermCondition />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
