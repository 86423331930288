import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="text-white p-2 my-1 bg-purple-500 rounded-lg text-center mx-3 text-md">
      <p>
        &copy; {currentYear}
        <span>
          <Link to="/" className="hover:underline ms-2">
            edxvision.xyz
          </Link>
        </span>
      </p>
    </footer>
  );
}
