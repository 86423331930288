import React, { useState } from "react";
import ParticlesDesign from "./ParticlesDesign";
import Loader from "./Loader";

export default function About() {
  const [isLoad, setIsLoad] = useState(true);
  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <Loader />
  ) : (
    <>
      <ParticlesDesign />
      <section className="p-6 text-green-700 rounded-lg shadow-md m-3 text-lg">
        <h1 className="text-3xl font-bold text-center">About Us</h1>
        <p className="my-3">
          Welcome to <b>edxvision.xyz!</b>
        </p>
        <p className="my-3">
          At edxvision.xyz, we are passionate about transforming education
          through technology and innovation. Our mission is to provide
          high-quality educational resources that cater to the diverse needs of
          learners around the globe.
        </p>

        <h6 className="font-bold">Our Story</h6>
        <p className="my-2">
          Founded in 2024, edxvision.xyz was established with the goal of
          making education more accessible, engaging, and effective. We believe
          in leveraging technology to break down barriers and create
          opportunities for all learners.
        </p>

        <h6 className="font-bold">Our Team</h6>
        <p className="my-2">
          Our team is composed of educators, technologists, and content creators
          who are dedicated to delivering exceptional learning experiences. We
          work collaboratively to develop content that is both informative and
          engaging.
        </p>

        <h6 className="font-bold">Our Services</h6>
        <ul className="list-disc mx-3 space-y-1 list-inside">
          <li className="my-2">
            <b>Online Courses:</b> A wide range of courses designed to meet the
            needs of learners at all levels.
          </li>
          <li className="my-2">
            <b>Interactive Learning:</b> Tools and resources to facilitate
            interactive and immersive learning experiences.
          </li>
          <li className="my-2">
            <b>Community Support:</b> A supportive community of learners and
            educators to foster collaboration and growth.
          </li>
        </ul>

        <h6 className="font-bold">Get In Touch</h6>
        <p className="my-2">
          Thank you for choosing edxvision.xyz as your educational partner. We
          are committed to supporting your learning journey.
        </p>
      </section>
    </>
  );
}
